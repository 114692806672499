//color palettes
$tel_aviv: (
        sea: #00AEBA,
        moss: #72D54A,
        iris: #A97AC8,
        clay: #AEA198,
        poppy: #FF5859,
        apricot: #FFAE75,
);

$jerusalem: (
        pewter: #706258,
        garnet: #D7106D,
        gold: #d68230,
        agate: #00B44E,
        lapis: #2e7DE1,
        amethyst: #B416AB
);

$negev: (
        sea-salt: #d8d1ca,
        salmon: #ffa388,
        firefly: #f3d09e,
        sky: #76c3d4,
        earth: #79420f
);